<template>
  <div
    class="tab-pane fade show active"
    id="about_tab"
    role="tabpanel"
    aria-labelledby="about-tab"
  >
    <ModuleLocked v-if="!sendEmailModule" />
    <div v-else class="about_tab_content">
      <section class="vid-title-sec">
        <div class="container">
          <form @submit.prevent="(e) => SEND_TO_MAIL(e, RESET_EMAIL)">
            <div class="vid-title my-2" v-if="getActiveUser.roleId !== 3">
              <h2 class="title-hd">{{ $t("sendMailAddress") }}</h2>
              <div class="form_field">
                <input
                  type="text"
                  maxlength="100"
                  required
                  disabled
                  v-model="emailForm.to"
                  :placeholder="$t('addHere')"
                />
              </div>
            </div>
            <div class="vid-title my-2">
              <h2 class="title-hd">{{ $t("enterMailTitle") }}</h2>
              <div class="form_field">
                <input
                  type="text"
                  maxlength="100"
                  required
                  v-model="emailForm.subject"
                  :placeholder="$t('addHere')"
                />
              </div>
            </div>
            <!--vid-title-->
            <div class="abt-vidz-pr">
              <h2 class="title-hd">{{ $t("enterMailDescription") }}</h2>
              <div class="form_field">
                <textarea
                  :placeholder="$t('addHere5000')"
                  required
                  v-model="emailForm.content"
                  maxlength="5000"
                ></textarea>
              </div>
            </div>
            <div class="mt-4" v-if="emailForm.files.length !== 0">
              <h5 class="mb-4">
                {{ $t("attachment") }} ({{ emailForm.files.length }})
              </h5>
              <div class="bg-light card">
                {{ $t("limit10Files") }}
              </div>
              <span
                class="btn btn-pill m-2"
                v-for="(file, i) in emailForm.files"
                :key="i"
                >{{ file.name }}
                <i
                  @click="emailForm.files.splice(i, 1)"
                  class="icon-cancel"
                ></i>
              </span>
            </div>
            <div class="mt-4 text-center" v-if="emailForm.files.length < 10">
              <button
                @click.prevent="$refs.file.click()"
                class="btn btn-pill btn-dark"
              >
                + {{ $t("addFile") }}
              </button>
              <input
                class="d-none"
                type="file"
                @change="SET_FILE"
                multiple
                name="file"
                id="file"
                ref="file"
              />
            </div>
            <div class="clearfix"></div>
            <div class="btn-sbmit">
              <button type="submit" :disabled="mailIsSending">
                <loading v-if="mailIsSending"></loading>
                <span v-else>{{ $t("sendMail") }}</span>
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Mail from "../../mixins/Mail";

export default {
  mixins: [Mail],
  props: {
    user: {
      default: () => {},
    },
  },
  methods: {
    RESET_EMAIL() {
      console.log("çalıştı mı?");
      this.emailForm.to = this.user.email;
      return true;
    },
  },
  mounted() {
    this.RESET_EMAIL();
  },
};
</script>

<style></style>
